"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DEV_ASSERT = exports.ASSERT = void 0;
const ASSERT = function (condition, message) {
  for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
    args[_key - 2] = arguments[_key];
  }
  console.assert(condition, message, ...args);
  if (!condition) throw new Error("Assertion failed: ".concat(message));
};
exports.ASSERT = ASSERT;
const DEV_ASSERT = function (condition, message) {
  for (var _len2 = arguments.length, args = new Array(_len2 > 2 ? _len2 - 2 : 0), _key2 = 2; _key2 < _len2; _key2++) {
    args[_key2 - 2] = arguments[_key2];
  }
  console.assert(condition, message, ...args);
};
exports.DEV_ASSERT = DEV_ASSERT;